import React, {Component} from 'react'
import Alert from '../../components/Alert/Alert'
import Anchor from '../../components/Anchor/Anchor'
import Container from '../../components/Container/Container'
import Content from '../../components/Content/Content'
import Spinner from  '../../components/UI/Spinner/Spinner'
import Stage from '../../components/Stage/Stage'
import BookCard from '../../components/BookCard/BookCard'
import Button from '../../components/UI/Button/Button'
import AlertImg from '../../assets/images/cotn-alert.png'
import COTNImg from '../../assets/images/cotn-1-book.png'
import COTN2Img from '../../assets/images/cotn-2-book.png'
import COTN3Img from '../../assets/images/cotn-3-book.png'
import BooksImg from '../../assets/images/books.png'
import BooksMobileImg from '../../assets/images/books-mobile.png'
import MichelleInIcelandImg from '../../assets/images/michelle-in-iceland.jpg'
import MichelleInIcelandMobileImg from '../../assets/images/michelle-in-iceland-mobile.jpg'
import MichelleImg from '../../assets/images/michelle-profile.jpg'
import Error from  '../../components/Error/Error'
import Empty from  '../../hoc/Empty/Empty'
import Modal from '../../components/UI/Modal/Modal'
import NewsletterForm from  '../../components/NewsletterForm/NewsletterForm'
import ReactGA4 from 'react-ga4'

class Home extends Component {
	state = {
		loading: true,
		errorMessage: '',
		showNewsletterModal: false
	}

	componentDidMount = () => {
		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')

		this.setState({loading: false})

		setTimeout(() => {
			this.setState({ showNewsletterModal: true })
		}, 6000)
	}

	newsletterClosedHandler = () => {
		this.setState({showNewsletterModal: false})
	}

	render() {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		return (
			<Empty>
				<Alert href="/buecher/circle-of-night-die-hexen-von-hamburg-urban-fantasy-trilogie" src={AlertImg} alt="Neu: »Circle of Night« - Young Adult Urban Fantasy" />
				<Anchor id="Home" />
				<Container>
					<Content imgRight img={MichelleImg} alt="Michelle and Greg">
						<h1>Willkommen auf meiner Website!</h1>
						<p>Hey, ihr Lieben! Ich bin Michelle C. Paige, Autorin von Romance und Fantasy, in den Bereichen Young Adult und New Adult.</p>
						<p>Ich schreibe, weil ich es liebe, weil Geschichten mein Leben sind und ich gar nicht anders kann, als sie zu erzählen. Und ich hoffe, ich kann euch mit auf Reisen in ferne Länder und fremde Welten nehmen.</p>
						<p>Hier findest du alles, was du über meine Bücher wissen musst.</p>
						<p>Mehr über meine <a href="/buecher">Bücher</a>.</p>
						<p>Erfahrt mehr über <a href="#About">mich</a>.</p>
					</Content>
				</Container>

				<Anchor id="Books" />
				<Container>
					<h1 className='text-center'>Neuerscheinung</h1>
					<Content img={COTN3Img} alt="Circle of Night - Die magische Bibliothek">
						<p><strong>Das spannende Finale der YA Urban Fantasy Reihe von Michelle C. Paige!</strong></p>
						<p>
							Jason verbringt mit seiner Schwester Josie und seinem Freund Niklas die Weihnachtsferien in Paris. Doch statt die Zeit zu genießen, kämpft Jason mit Schuldgefühlen: Denn seit seine Kräfte geschwunden sind, kann er niemanden mehr beschützen - und Niklas wäre deswegen fast gestorben.<br />
							Als sich die Möglichkeit ergibt, in der berühmten magischen Bibliothek von Paris eine Heilung zu finden, mit der er seine Kräfte wieder aufbauen kann, ergreift er sie sofort.<br />
							Doch wo es Magie und Hexende gibt, sind magische Schwierigkeiten nicht weit. In den Katakomben der Bibliothek erwachen unruhige Geister ...
						</p>
						<p><strong>Der dritte Band der spannenden YA Urban Fantasy Reihe »Circle of Night« um die jungen Hexen und Hexer, die sich den Mächten des Bösen entgegenstellen und für Freundschaft, Liebe und eine bessere Welt alles riskieren.</strong></p>
						<Button href="/buecher/circle-of-night-die-hexen-von-hamburg-urban-fantasy-trilogie" gainfos="Circle of Night - Die magische Bibliothek - Home">Zum Buch</Button>
					</Content>
					<Content img={COTN2Img} alt="Circle of Night - Die geheime Akademie">
						<p><strong>Eine YA Urban Fantasy über eine magische Akademie und den Kampf gegen gefährliche Wesen aus dem Reich der Toten voller Plottwists und Gefühl</strong></p>
						<p>
							Josie erschreckt sich beinahe zu Tode, als ihr in Noahs eigentlich geheimer Bibliothek plötzlich Matteo gegenübersteht - ein fremder Hexer aus einem großen Londoner Hexenzirkel. Er ist auf der Suche nach einem Buch, das sich in Noahs Besitz befinden soll. Doch als er sieht, dass Josie und ihre Freunde keinen Hexenzirkel haben, bietet er ihnen an, dass sie für die Ferien die Akademie für Hexende in London besuchen können.<br />
							Josie ist zunächst misstrauisch, doch der Ausblick, mehr über Hexerei zu lernen und neue Kontakte zu knüpfen, überzeugt sie schließlich doch. Gemeinsam mit Gizem, Jason, Niklas und Louisa reist sie kurz darauf nach England.<br />
							Dort eröffnet sich ihnen eine völlig neue Welt, und sie können ihre Kräfte in der magischen Akademie verbessern. Schnell finden sie Freunde, und auch Josie und Matteo kommen sich näher.<br />
							Doch es gibt einen Haken: In London treiben dunkle Mächte ihr Unwesen, und der Hexenzirkel befindet sich mitten in einem Kampf. Untote Monster entkommen durch instabile Portale aus dem Reich der Toten - und sie greifen nicht nur die Hexenden aus London an ...
						</p>
						<p><strong>Der zweite Band der spannenden YA Urban Fantasy Reihe »Circle of Night« um die jungen Hexen und Hexer, die sich den Mächten des Bösen entgegenstellen und für Freundschaft, Liebe und eine bessere Welt alles riskieren.</strong></p>
						<Button href="/buecher/circle-of-night-die-hexen-von-hamburg-urban-fantasy-trilogie" gainfos="Circle of Night - Die geheime Akademie - Home">Zum Buch</Button>
					</Content>
					<Content img={COTNImg} alt="Circle of Night - Das Reich der Schatten">
						<p><strong>In Hamburg treiben dunkle Schatten ihr Unwesen</strong></p>
						<p>
							Die 17-jährige Louisa kann nur knapp einem Monster aus Feuer entkommen, das in der Elbphilharmonie wütet. Doch niemand außer ihr sieht dieses Wesen.<br />
							Erst durch den mysteriösen Noah erfährt Louisa, was sie ist: eine Hexe! Und auf die haben es die Monster ganz besonders abgesehen. Aber Louisa ist nicht allein, denn Niklas, die Zwillinge Jason und Josie sowie Gizem haben ähnliches erlebt. Noah bietet seine Hilfe an und bringt der Gruppe bei, wie sie ihre Magie einsetzen und die gefährlichen Schatten bekämpfen können.<br />
							Während Louisa bei den Übungsstunden aufblüht, da sie endlich versteht, weshalb sie sich immer schon so anders gefühlt hat, sind sie für Niklas kaum auszuhalten. Und das nicht nur, weil zum Erlernen von Magie auch das Wälzen von dicken Zauberbüchern gehört. Sondern vor allem, weil auch ein bekanntes Gesicht Teil ihrer kleinen Gruppe ist: Jason. Der Jason, der einst sein Leben zerstört hat. Doch für Fehden innerhalb der Gruppe fehlt ihnen die Zeit. Sie müssen schnell stärker werden und zusammenarbeiten, um den Schattenwesen etwas entgegenzusetzen. Denn es werden immer mehr, und sie werden hungriger ...
						</p>
						<p><strong>Auftakt der spannenden Reihe »Circle of Night« um die fünf jungen Hexen und Hexer, die sich den Mächten des Bösen entgegenstellen und für Freundschaft, Liebe und eine bessere Welt alles riskieren.</strong></p>
						<Button href="/buecher/circle-of-night-die-hexen-von-hamburg-urban-fantasy-trilogie" gainfos="Circle of Night - Das Reich der Schatten - Home">Zum Buch</Button>
					</Content>
				</Container>
				<a href="/buecher">
				</a>
				<Container>
					<BookCard className="full" link="/buecher">
						<picture>
							<source media="(max-width: 767px)" srcSet={BooksMobileImg} />
							<source media="(min-width: 768px)" srcSet={BooksImg} />
							<img src={BooksImg} alt="Circle of Night - Das Reich der Schatten" />
						</picture>
						<Button href="/buecher" gainfos="Bücher - Home">Zu meinen Büchern</Button>
					</BookCard>
				</Container>

				<Anchor id="About" />
				<Stage desktop={MichelleInIcelandImg} mobile={MichelleInIcelandMobileImg} alt="Michelle in Island" />
				<Container>
					<h1 className='text-center'>Über mich</h1>

					<p>
						Schon als Kind habe ich in jedes Freundebuch als Traumberuf »Schriftstellerin« geschrieben, denn meine Leidenschaft ist und war schon immer nur eins: Geschichten schreiben. In all ihren Formen und Farben.<br />
						Geschichten sind mein Leben.
					</p>
					<p>Wenn man mich nicht in einem meiner vielen Tagträume findet, dann vermutlich irgendwo in Island, wo ich seit 2016 jedes Jahr hingereist bin, mein Herz verloren habe und seit Sommer 2022 auch lebe.</p>
					<p>Neuerdings studiere ich an der Icelandic Filmschool Drehbuchschreiben und Regie, um Geschichten nicht nur auf dem Papier sondern auch auf dem Bildschirm Leben einzuhauchen.</p>

					<p>Erfahrt mehr über meine Geschichten <a href="/buecher">hier</a>.<br />
						Erfahrt mehr über ihre aktuellen Projekte, meine Islandabenteuer und mein alltägliches mehr oder weniger verrücktes Autorenleben auf <a href="https://www.instagram.com/michellecpaige_autorin/">Instagram</a>.</p>
				</Container>

				<Modal show={this.state.showNewsletterModal} modalClosed={this.newsletterClosedHandler}>
					<h1 className="text-center">Newsletter</h1>
					<p className="text-center">Bleibt auf dem Laufenden und meldet euch zum Newsletter an!</p>
					<NewsletterForm />
				</Modal>
			</Empty>
		)
	}
}

export default Home
