import React, { Component } from 'react'
import Spinner from  '../../../components/UI/Spinner/Spinner'
import Container from  '../../../components/Container/Container'
import Content from '../../../components/Content/Content'
import Shops from '../../../components/Shops/Shops'
import Error from  '../../../components/Error/Error'
import COTN1Img from '../../../assets/images/cotn-1-book.png'
import COTN2Img from '../../../assets/images/cotn-2-book.png'
import COTN3Img from '../../../assets/images/cotn-3-book.png'
import ReactGA4 from 'react-ga4'
import {Helmet} from 'react-helmet'

class CircleOfNight extends Component {
	state = {
		loading: true,
		errorMessage: ''
	}

	componentDidMount = () => {
		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')

		this.setState({
			loading: false
		})
	}

	render () {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		return (
			<div className='CircleOfNight'>
				<Helmet>
					<title>Circle of Night - Trilogie | Die Hexen und Hexer von Hamburg | Young Adult Fantasy</title>
					<meta name="description" content="Fünf junge Hexen und Hexer aus Hamburg müssen sich dem Bösen entgegenstellen und für ihre Freunde alles riskieren." />
				</Helmet>
				<Container>
					<h1 className='text-center'>Circle of Night - Young Adult Urban Fantasy Trilogie</h1>
					<strong className='block text-center'>Als eBooks & Hörbücher erhältlich</strong>
					<Content img={COTN1Img} alt="Circle of Night - Das Reich der Schatten (Band 1)">
						<h2>Circle of Night - Das Reich der Schatten (Band 1)</h2>
						<p><strong>In Hamburg treiben dunkle Schatten ihr Unwesen</strong></p>
						<p>
							Die 17-jährige Louisa kann nur knapp einem Monster aus Feuer entkommen, das in der Elbphilharmonie wütet. Doch niemand außer ihr sieht dieses Wesen.<br />
							Erst durch den mysteriösen Noah erfährt Louisa, was sie ist: eine Hexe! Und auf die haben es die Monster ganz besonders abgesehen. Aber Louisa ist nicht allein, denn Niklas, die Zwillinge Jason und Josie sowie Gizem haben ähnliches erlebt. Noah bietet seine Hilfe an und bringt der Gruppe bei, wie sie ihre Magie einsetzen und die gefährlichen Schatten bekämpfen können.<br />
							Während Louisa bei den Übungsstunden aufblüht, da sie endlich versteht, weshalb sie sich immer schon so anders gefühlt hat, sind sie für Niklas kaum auszuhalten. Und das nicht nur, weil zum Erlernen von Magie auch das Wälzen von dicken Zauberbüchern gehört. Sondern vor allem, weil auch ein bekanntes Gesicht Teil ihrer kleinen Gruppe ist: Jason. Der Jason, der einst sein Leben zerstört hat. Doch für Fehden innerhalb der Gruppe fehlt ihnen die Zeit. Sie müssen schnell stärker werden und zusammenarbeiten, um den Schattenwesen etwas entgegenzusetzen. Denn es werden immer mehr, und sie werden hungriger ...
						</p>
						<p><strong>Auftakt der spannenden Reihe »Circle of Night« um die fünf jungen Hexen und Hexer, die sich den Mächten des Bösen entgegenstellen und für Freundschaft, Liebe und eine bessere Welt alles riskieren.</strong></p>
						<ul>
							<li><em>Format: eBook</em></li>
							<li><em>Erscheinungstermin: 1. März 2024</em></li>
							<li><em>Verlag: <a href="https://www.luebbe.de/one/ebooks/junge-erwachsene/circle-of-night-die-hexen-von-hamburg-urban-fantasy-trilogie/id_9911713" target='_blank' rel='noopener noreferrer'>ONE Verlag</a></em></li>
							<li><em>ISBN: 9783751748643</em></li>
						</ul>
						<Shops shops={[
							{
								label: 'Amazon',
								href: 'https://www.amazon.de/dp/B0CNKM9NL8/',
								target: '_blank',
								GAInfos: 'Circle of Night - Das Reich der Schatten - Amazon'
							},
							{
								label: 'Thalia',
								href: 'https://www.thalia.de/shop/home/artikeldetails/A1070308070',
								target: '_blank',
								GAInfos: 'Circle of Night - Das Reich der Schatten - Thalia'
							},
							{
								label: 'Hugendubel',
								href: 'https://www.hugendubel.de/de/ebook_epub/michelle_c_paige-circle_of_night_das_reich_der_schatten-47216571-produkt-details.html',
								target: '_blank',
								GAInfos: 'Circle of Night - Das Reich der Schatten - Hugendubel'
							}
						]} onclick={this.shopClickHandler} />
					</Content>
					<Content img={COTN2Img} alt="Circle of Night - Die geheime Akademie (Band 2)">
						<h2>Circle of Night - Die geheime Akademie (Band 2)</h2>
						<p><strong>Eine YA Urban Fantasy über eine magische Akademie und den Kampf gegen gefährliche Wesen aus dem Reich der Toten voller Plottwists und Gefühl</strong></p>
						<p>
							Josie erschreckt sich beinahe zu Tode, als ihr in Noahs eigentlich geheimer Bibliothek plötzlich Matteo gegenübersteht - ein fremder Hexer aus einem großen Londoner Hexenzirkel. Er ist auf der Suche nach einem Buch, das sich in Noahs Besitz befinden soll. Doch als er sieht, dass Josie und ihre Freunde keinen Hexenzirkel haben, bietet er ihnen an, dass sie für die Ferien die Akademie für Hexende in London besuchen können.<br />
							Josie ist zunächst misstrauisch, doch der Ausblick, mehr über Hexerei zu lernen und neue Kontakte zu knüpfen, überzeugt sie schließlich doch. Gemeinsam mit Gizem, Jason, Niklas und Louisa reist sie kurz darauf nach England.<br />
							Dort eröffnet sich ihnen eine völlig neue Welt, und sie können ihre Kräfte in der magischen Akademie verbessern. Schnell finden sie Freunde, und auch Josie und Matteo kommen sich näher.<br />
							Doch es gibt einen Haken: In London treiben dunkle Mächte ihr Unwesen, und der Hexenzirkel befindet sich mitten in einem Kampf. Untote Monster entkommen durch instabile Portale aus dem Reich der Toten - und sie greifen nicht nur die Hexenden aus London an ...
						</p>
						<p><strong>Der zweite Band der spannenden YA Urban Fantasy Reihe »Circle of Night« um die jungen Hexen und Hexer, die sich den Mächten des Bösen entgegenstellen und für Freundschaft, Liebe und eine bessere Welt alles riskieren.</strong></p>
						<ul>
							<li><em>Format: eBook</em></li>
							<li><em>Erscheinungstermin: 1. Juni 2024</em></li>
							<li><em>Verlag: <a href="https://www.luebbe.de/one/ebooks/junge-erwachsene/circle-of-night-die-geheime-akademie/id_10263324" target='_blank' rel='noopener noreferrer'>ONE Verlag</a></em></li>
							<li><em>ISBN: 9783751755429</em></li>
						</ul>
						<Shops shops={[
							{
								label: 'Amazon',
								href: 'https://www.amazon.de/gp/product/B0CW1J7RCR',
								target: '_blank',
								GAInfos: 'Circle of Night - Die geheime Akademie - Amazon'
							},
							{
								label: 'Thalia',
								href: 'https://www.thalia.de/shop/home/artikeldetails/A1071153374',
								target: '_blank',
								GAInfos: 'Circle of Night - Die geheime Akademie - Thalia'
							},
							{
								label: 'Hugendubel',
								href: 'https://www.hugendubel.de/de/ebook_epub/circle_of_night_die_geheime_akademie-47693231-produkt-details.html',
								target: '_blank',
								GAInfos: 'Circle of Night - Die geheime Akademie - Hugendubel'
							}
						]} onclick={this.shopClickHandler} />
					</Content>
					<Content img={COTN3Img} alt="Circle of Night - Die magische Bibliothek (Band 3)">
						<h2>Circle of Night - Die magische Bibliothek (Band 3)</h2>
						<p><strong>Das spannende Finale der YA Urban Fantasy Reihe von Michelle C. Paige!</strong></p>
						<p>
							Jason verbringt mit seiner Schwester Josie und seinem Freund Niklas die Weihnachtsferien in Paris. Doch statt die Zeit zu genießen, kämpft Jason mit Schuldgefühlen: Denn seit seine Kräfte geschwunden sind, kann er niemanden mehr beschützen - und Niklas wäre deswegen fast gestorben.<br />
							Als sich die Möglichkeit ergibt, in der berühmten magischen Bibliothek von Paris eine Heilung zu finden, mit der er seine Kräfte wieder aufbauen kann, ergreift er sie sofort.<br />
							Doch wo es Magie und Hexende gibt, sind magische Schwierigkeiten nicht weit. In den Katakomben der Bibliothek erwachen unruhige Geister ...
						</p>
						<p><strong>Der dritte Band der spannenden YA Urban Fantasy Reihe »Circle of Night« um die jungen Hexen und Hexer, die sich den Mächten des Bösen entgegenstellen und für Freundschaft, Liebe und eine bessere Welt alles riskieren.</strong></p>
						<ul>
							<li><em>Format: eBook</em></li>
							<li><em>Erscheinungstermin: 1. Februar 2025</em></li>
							<li><em>Verlag: <a href="https://www.luebbe.de/one/ebooks/junge-erwachsene/circle-of-night-die-magische-bibliothek/id_10263332" target='_blank' rel='noopener noreferrer'>ONE Verlag</a></em></li>
							<li><em>ISBN: 9783751755436</em></li>
						</ul>
						<Shops shops={[
							{
								label: 'Amazon',
								href: 'https://www.amazon.de/gp/product/B0D261NQ3K',
								target: '_blank',
								GAInfos: 'Circle of Night - Die magische Bibliothek - Amazon'
							},
							{
								label: 'Thalia',
								href: 'https://www.thalia.de/shop/home/artikeldetails/A1071620527',
								target: '_blank',
								GAInfos: 'Circle of Night - Die magische Bibliothek - Thalia'
							},
							{
								label: 'Hugendubel',
								href: 'https://www.hugendubel.de/de/ebook_epub/circle_of_night_die_magische_bibliothek-48006810-produkt-details.html',
								target: '_blank',
								GAInfos: 'Circle of Night - Die magische Bibliothek - Hugendubel'
							}
						]} onclick={this.shopClickHandler} />
					</Content>
				</Container>
			</div>
		)
	}
}

export default CircleOfNight